import { type FC } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ProvideAuth } from '@lib/hooks'
import { AuthenticatedRoute, ScrollToTop } from '@lib/components'

import { CampaignCreate, CharityList, CharityMatchPots, CreateMatchPot, Home, Login, MatchPotCharityList } from '~/pages'

import { Header } from '~/components'
import { browserExceptions } from '@lib/services'
import { ActiveCampaign, Products } from '@shamaazi/mytennights'
import { CharityStripeAccounts } from '~/pages/CharityStripeAccounts'

void browserExceptions.initialize()

const queryClient = new QueryClient()

const App: FC = () => {
  return <ProvideAuth>
    <QueryClientProvider client={queryClient}>
      <Router>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<AuthenticatedRoute loginComponent={<Login />}>
            <Home />
          </AuthenticatedRoute>} />
          <Route path="/accounts" element={<AuthenticatedRoute loginComponent={<Login />}>
            <CharityStripeAccounts />
          </AuthenticatedRoute>} />
          <Route path="/match-pots/:charityId/create" element={<AuthenticatedRoute loginComponent={<Login />}>
            <CreateMatchPot />
          </AuthenticatedRoute>} />
          <Route path="/match-pots" element={<AuthenticatedRoute loginComponent={<Login />}>
            <MatchPotCharityList />
          </AuthenticatedRoute>} />
          <Route path="/match-pots/:charityID" element={<AuthenticatedRoute loginComponent={<Login />}>
            <CharityMatchPots />
          </AuthenticatedRoute>} />
          <Route path="/mytennights" element={<AuthenticatedRoute loginComponent={<Login />}>
            <CharityList product={Products.myTenNights} currentCampaign={ActiveCampaign.myTenNights} />
          </AuthenticatedRoute>} />
          <Route path="/mytennights/:charityID" element={<AuthenticatedRoute loginComponent={<Login />}>
            <CampaignCreate product={Products.myTenNights} currentCampaign={ActiveCampaign.myTenNights} />
          </AuthenticatedRoute>} />
          <Route path="/mytendays" element={<AuthenticatedRoute loginComponent={<Login />}>
            <CharityList product={Products.myTenDays} currentCampaign={ActiveCampaign.myTenDays} />
          </AuthenticatedRoute>} />
          <Route path="/mytendays/:charityID" element={<AuthenticatedRoute loginComponent={<Login />}>
            <CampaignCreate product={Products.myTenDays} currentCampaign={ActiveCampaign.myTenDays} />
          </AuthenticatedRoute>} />
          {/* <Route>
            <main>
              <div className="px-4 mt-4">
                <h1 className="font-mono text-lg font-bold text-sz-gray-700">This page could not be found</h1>
              </div>
            </main>
          </Route> */}
        </Routes>
      </Router>
    </QueryClientProvider>
  </ProvideAuth>
}

export default App
